import React, { Component } from 'react'
import supratim from '../../content/images/personal_picture.jpg'

export default class UserInfo extends Component {
  render() {
    return (
      <aside className="note">
        <div className="container note-container">
          <div className="flex-author">
            <div className="flex-avatar">
              <img className="avatar" src={supratim} alt="Supratim" />
            </div>
            <div>
              <p>
                I’m Supratim. I am a user experience designer for digital products. I enjoy making software, building communities and producing technical content. I pursue an interest in human-computer interaction research.{' '}
                <strong>
                  If you like this content, please consider following me on twitter.
                </strong>
              </p>

              <div className="flex">
                <a
                  href="https://twitter.com/heysupratim"
                  className="follow-button"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  @heysupratim
                </a>
              </div>
            </div>
          </div>
        </div>
      </aside>
    )
  }
}
